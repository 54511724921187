import React from 'react';
import { SvgIcon } from '../../base-components/SvgIcon';
import { IconsProps } from './Icons.type';

/** Зелёная градиентом галочка с острыми краями */
export const CheckIcon: React.FC<IconsProps> = ({ className }) => (
    <SvgIcon width={16} height={18} className={className} noFill noStroke>
        <path
            d="M.571 7.796 0 8.14c.914 3.554 2.857 7.338 4.914 9.86l.572-.23C7.314 13.3 10.97 5.619 16 .574L15.657 0C11.314 3.44 7.2 8.37 4.457 12.955 3.314 11.924 1.6 9.401.571 7.796Z"
            fill="url(#a)"
        />
        <defs>
            <linearGradient
                id="a"
                x1=".001"
                y1="9.004"
                x2="15.999"
                y2="9.004"
                gradientUnits="userSpaceOnUse"
            >
                <stop offset="0" stopColor="#AAD485" />
                <stop offset=".02" stopColor="#AAD485" />
                <stop offset="1" stopColor="#6CBE45" />
            </linearGradient>
        </defs>
    </SvgIcon>
);
