import moment from 'moment';
// @ts-ignore
import momentDurationFormatSetup from 'moment-duration-format';
import toMskMoment from './toMskMoment';

const dateConstants = {
    month: {
        momentJs: 'MM.YYYY',
        monthPicker: 'MM.YYYY',
        dateTimePicker: {
            date: 'mm.yy',
            time: 'HH:mm',
        },
        inputMask: {
            alias: 'datetime',
            placeholder: 'мм.гггг',
            inputFormat: 'mm.yyyy',
        },
    },
    time: {
        momentJs: 'DD.MM.YYYY HH:mm',
        dateTimePicker: {
            date: 'dd.mm.yy',
            time: 'HH:mm',
        },
        inputMask: {
            alias: 'datetime',
            placeholder: 'дд.мм.гггг чч:мм',
            inputFormat: 'dd.mm.yyyy HH:MM',
        },
    },
    timeOnly: {
        momentJs: 'HH:mm',
        dateTimePicker: {
            date: '',
            time: 'HH:mm',
        },
        inputMask: {
            alias: 'datetime',
            placeholder: 'чч:мм',
            inputFormat: 'HH:MM',
        },
    },
    date: {
        momentJs: 'DD.MM.YYYY',
        dateTimePicker: {
            date: 'dd.mm.yy',
            time: 'HH:mm',
        },
        inputMask: {
            alias: 'datetime',
            placeholder: 'дд.мм.гггг',
            inputFormat: 'dd.mm.yyyy',
        },
    },
    dateLong: {
        momentJs: 'LL',
    },
    datetime: {
        momentJs: 'DD.MM.YYYY HH:mm',
        dateTimePicker: {
            date: 'dd.mm.yy',
            time: 'HH:mm',
        },
        inputMask: {
            alias: 'datetime',
            placeholder: 'дд.мм.гггг чч:мм',
            inputFormat: 'dd.mm.yyyy HH:MM',
        },
    },
    datetimeLong: {
        momentJs: 'DD MMMM YYYY HH:mm',
    },
    datetimeSeconds: {
        momentJs: 'DD MMMM YYYY HH:mm:ss',
    },
    monthYearLong: {
        momentJs: 'MMMM YYYY',
    },
};

momentDurationFormatSetup(moment);

export default function formatMomentDate(
    date: any,
    format: keyof typeof dateConstants = 'time',
    placeholder = ''
) {
    if (!date) {
        return '';
    }

    const momentDate = toMskMoment(date);

    return momentDate && momentDate.isValid() && dateConstants[format]
        ? momentDate.format(dateConstants[format].momentJs)
        : placeholder;
}
