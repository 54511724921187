import { FC, useMemo } from 'react';
import { Form, Formik } from 'formik';
import { Button } from '../../../base-components/Button';
import { FormTextArea } from '../../../base-components/FormTextArea';
import { FormTextInput } from '../../../base-components/FormTextInput';
import styles from './SendMessageForm.module.scss';
import { Props } from './SendMessageForm.type';

export const SendMessageForm: FC<Props> = ({
    handleSubmit,
    textAreLabel,
    contactLabel,
    validationSchema,
    initialValues: externalValues,
}) => {
    const initialValues = useMemo(() => {
        return {
            name: '',
            contact: '',
            message: '',
            ...externalValues,
        };
    }, [externalValues]);

    return (
        <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
        >
            {(formik) => (
                <Form className={styles.form}>
                    <FormTextInput
                        name="name"
                        appearance="compact"
                        label="Ваше имя"
                        className={styles.field}
                    />
                    <FormTextInput
                        name="contact"
                        appearance="compact"
                        label={contactLabel}
                        className={styles.field}
                    />
                    <FormTextArea
                        name="message"
                        label={textAreLabel}
                        className={styles.message}
                    />
                    <div className={styles.footer}>
                        <Button
                            variant="blue"
                            disabled={formik.isSubmitting}
                            type="submit"
                            className={styles.sendBtn}
                        >
                            Отправить
                        </Button>
                    </div>
                </Form>
            )}
        </Formik>
    );
};
