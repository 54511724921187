import { OfferProps } from '../Tariffs/Offer';
import { SpecialOfferProps } from '../Tariffs/SpecialOffer';

export const tariffNames = ['licenses', 'vipLicenses'] as const;
export type TariffNames = typeof tariffNames[number];
export const tariffTitles: Record<TariffNames, string> = {
    licenses: 'Лицензии стандарт',
    vipLicenses: 'Лицензии VIP',
};

export const tariffsDummy: {
    services: { customers: OfferProps[]; participants: OfferProps[] };
    licenses: OfferProps[];
    vipLicenses: OfferProps[];
} = {
    services: {
        customers: [
            {
                list: [
                    'Регистрация на портале ESTP.RU',
                    'Проведение процедур закупок',
                    'Юридическое и техническое сопровождение',
                    'Подбор подрядчиков/ поставщиков',
                    'Проверка по РНП (реестр недобросовестных поставщиков)',
                ],
                action: 'free',
                tariffTitle: 'Стандарт',
            },
            {
                tariffTitle: 'Партнёр',
                action: 'connect',
                color: 'free',
                conditions: {
                    price: '100 000 ₽',
                    withNds: false,
                },
                price: 100_000,
                additional: [
                    'Стоимость одной закупки,',
                    'на которой есть хотя бы один участник,',
                    'будет составлять 19 900 ₽',
                ],
            },
            {
                tariffTitle: 'VIP',
                action: 'connect',
                color: 'free',
                conditions: {
                    price: '200 000 ₽',
                    withNds: false,
                },
                vipLink: {
                    title: 'Что входит в тариф?',
                    url: '#',
                },
            },
        ],
        participants: [],
    },
    licenses: [
        {
            title: 'Бесплатно',
            name: 'g0_l100',
            action: 'none',
            color: 'connect',
            columns: [
                'НМЦ до 99\u00A0999,\u00A099\u00A0₽',
                'Бесплатно',
                'Бесплатно',
            ],
            tariffType: 'single',
        },
        {
            title: 'Лицензия за участие',
            name: 'G100',
            action: 'none',
            color: 'connect',
            columns: [
                'НМЦ от 100\u00A0000\u00A0₽',
                '1% от НМЦ, но не менее 7\u00A0500\u00A0₽ и не более 175\u00A0000\u00A0₽',
                '2% от НМЦ, но не менее 10\u00A0000\u00A0₽ и не более 350\u00A0000\u00A0₽',
            ],
            tariffType: 'single',
        },
        {
            title: 'С победителя закупки',
            name: 'e0',
            action: 'none',
            color: 'buy',
            columns: ['НМЦ не указана или равна 0', '12 000 ₽', '19 900 ₽'],
            tariffType: 'single',
        },
        {
            title: 'С победителя закупки',
            name: 'mzcd',
            action: 'none',
            color: 'buy',
            columns: ['Торги за единицу', '7 500 ₽', '11 000 ₽'],
            description: 'Разовая лицензия с победителя',
            tariffType: 'single',
        },
    ],
    vipLicenses: [
        {
            title: 'Бесплатно (VIP)',
            name: 'vip_g0_l100',
            action: 'none',
            color: 'buy',
            columns: ['НМЦ до 99\u00A0999,\u00A099\u00A0₽', 'Бесплатно'],
            tariffType: 'single',
        },
        {
            title: 'С победителя закупки (VIP)',
            name: 'vip_G100',
            action: 'none',
            color: 'buy',
            columns: [
                `НМЦ от 100\u00A0000\u00A0₽`,
                '0,3% от НМЦ, но не менее 4\u00A0500\u00A0₽ и не более 19\u00A0900\u00A0₽',
            ],
            conditions: {
                withNds: false,
            },
            tariffType: 'single',
        },
        {
            title: 'С победителя закупки (VIP)',
            name: 'vip_e0',
            action: 'none',
            color: 'buy',
            columns: ['НМЦ не указана или равна 0', '19\u00A0900\u00A0₽'],
            tariffType: 'single',
        },
    ],
};

export const specialOfferDummy: SpecialOfferProps = {
    // title: 'Подписка на базовые модули ЭТП',
    name: 'basic_subscription',
    action: 'connect',
    color: 'buy',
    tabs: [
        {
            title: 'Разовая',
            name: 'optimal_single',
            conditions: {},
            price: '7 000 ₽',
        },
        {
            title: '12 месяцев',
            name: 'optimal_12',
            conditions: {},
            price: '35 000 ₽',
        },
    ],
    link: '#',
};
