import { FC } from 'react';
import React from 'react';
import { SvgIcon } from '../../base-components/SvgIcon';

export const ContractorIcon: FC = () => (
    <SvgIcon width={21} height={22} noFill>
        <path
            d="M2.77344 6.51001L10.4997 10.9812L18.1734 6.53623"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M10.5 18.9087V10.9724"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M8.68928 2.17L4.01678 4.76878C2.95803 5.35503 2.0918 6.82501 2.0918 8.03251V12.9763C2.0918 14.1838 2.95803 15.6538 4.01678 16.24L8.68928 18.8388C9.68678 19.39 11.323 19.39 12.3205 18.8388L16.993 16.24C18.0518 15.6538 18.918 14.1838 18.918 12.9763V8.03251C18.918 6.82501 18.0518 5.35503 16.993 4.76878L12.3205 2.17C11.3143 1.61 9.68678 1.61 8.68928 2.17Z"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M14.8741 11.5849V8.38244L6.57031 3.5874"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </SvgIcon>
);
