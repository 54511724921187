/* eslint-disable react/jsx-props-no-spreading */
import { NextPage } from 'next';
import type { AppProps } from 'next/app';
import React from 'react';
import '../browser';
import { BreadcrumbsProvider } from '../components/_shared/Breadcrumbs/BreadcrumbsContext';
import { Layout } from '../components/Layout';
import { UserProvider } from '../components/UserContext';
import { useAnalytics } from '../lib/analytics';
import { AnalyticsSnippet } from '../lib/analytics';
import { PushNotification } from '../lib/pushNotification';
import { useScrollRestoration } from '../utils/hooks/useScrollRestoration';
import '../styles/globals.scss';

type NextPageWithLayout = NextPage & {
    LayoutComponent?: React.ComponentType;
};

type AppPropsWithLayout = AppProps & {
    Component: NextPageWithLayout;
};

export default function MyApp({
    Component,
    pageProps,
    router,
}: AppPropsWithLayout) {
    // Use the layout defined at the page level, if available
    const LayoutComponent = Component.LayoutComponent ?? Layout;

    useAnalytics();
    useScrollRestoration(router);

    return (
        <>
            <AnalyticsSnippet />
            <PushNotification />

            <UserProvider>
                <BreadcrumbsProvider>
                    <LayoutComponent>
                        <Component {...pageProps} />
                    </LayoutComponent>
                </BreadcrumbsProvider>
            </UserProvider>
        </>
    );
}
