import React, { FunctionComponent } from 'react';

import { IconsProps } from './Icons.type';

export const SearchIcon: FunctionComponent<IconsProps> = (props) => (
    <svg
        width="17"
        height="17"
        viewBox="0 0 17 17"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M9.45959 12.3694C12.5207 12.3694 15.0022 9.8243 15.0022 6.68472C15.0022 3.54514 12.5207 1 9.45959 1C6.3985 1 3.91699 3.54514 3.91699 6.68472C3.91699 9.8243 6.3985 12.3694 9.45959 12.3694Z"
            stroke="#A0A4B5"
            strokeWidth="2"
        />
        <path
            d="M5.70666 11.1726L1 16"
            stroke="#A0A4B5"
            strokeWidth="2"
            strokeLinecap="round"
        />
    </svg>
);
