import { FC } from 'react';
import React from 'react';
import { SvgIcon } from '../../base-components/SvgIcon';

export const CustomersIcon: FC = () => (
    <SvgIcon width={20} height={22} noFill>
        <path
            d="M15.1161 18.0166C14.3828 18.2333 13.5161 18.3333 12.4995 18.3333H7.49948C6.48281 18.3333 5.61615 18.2333 4.88281 18.0166C5.06615 15.85 7.29115 14.1416 9.99948 14.1416C12.7078 14.1416 14.9328 15.85 15.1161 18.0166Z"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M12.4993 1.66675H7.49935C3.33268 1.66675 1.66602 3.33341 1.66602 7.50008V12.5001C1.66602 15.6501 2.61602 17.3751 4.88268 18.0167C5.06602 15.8501 7.29102 14.1417 9.99935 14.1417C12.7077 14.1417 14.9327 15.8501 15.116 18.0167C17.3827 17.3751 18.3327 15.6501 18.3327 12.5001V7.50008C18.3327 3.33341 16.666 1.66675 12.4993 1.66675ZM9.99935 11.8084C8.34935 11.8084 7.01601 10.4668 7.01601 8.81676C7.01601 7.16676 8.34935 5.83341 9.99935 5.83341C11.6493 5.83341 12.9827 7.16676 12.9827 8.81676C12.9827 10.4668 11.6493 11.8084 9.99935 11.8084Z"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M12.9842 8.8166C12.9842 10.4666 11.6509 11.8082 10.0009 11.8082C8.35091 11.8082 7.01758 10.4666 7.01758 8.8166C7.01758 7.1666 8.35091 5.83325 10.0009 5.83325C11.6509 5.83325 12.9842 7.1666 12.9842 8.8166Z"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </SvgIcon>
);
