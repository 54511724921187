import moment from 'moment';
import Link from 'next/link';
import React from 'react';
import { rootLinks } from '../Links';
import styles from './Footer.module.scss';

export const Footer: React.FC = () => {
    const nowYear = moment().get('year');
    return (
        <footer>
            <div className={styles.container}>
                <div className={styles.column}>
                    <div className={styles.links}>
                        <div className={styles.column}>
                            <Link href={{ pathname: rootLinks.info.url }}>
                                <a className={styles.link}>О площадке</a>
                            </Link>
                            <Link href={rootLinks.purchases.url}>
                                <a className={styles.link}>Закупки</a>
                            </Link>
                            <Link href={rootLinks.faq.url}>
                                <a className={styles.link}>FAQ</a>
                            </Link>
                        </div>
                        <div className={styles.column}>
                            <Link href={rootLinks.documents.url}>
                                <a className={styles.link}>Документы</a>
                            </Link>
                            <Link href={rootLinks.requisites.url}>
                                <a className={styles.link}>Реквизиты</a>
                            </Link>
                            <Link href={`${rootLinks.tariffs.url}/licenses`}>
                                <a className={styles.link}>Тарифы</a>
                            </Link>
                        </div>
                    </div>
                </div>
                <a
                    href="https://sk.ru/"
                    target="_blank"
                    rel="noreferrer"
                    className={styles.skolkovoIcon}
                >
                    <img src="/assets/footer/skolkovo.svg" alt="skolkovo" />
                </a>
                <div className={styles.column}>
                    <div className={styles.header}>Техподдержка:</div>
                    <a className={styles.link} href="tel:88005552083">
                        8 800 555 20 83
                    </a>
                    <a className={styles.link} href="mailto:support@estp.ru">
                        support@estp.ru
                    </a>
                    <div className={styles.copyright}>
                        ООО «Единая система торговых процедур» {nowYear}
                    </div>
                </div>
            </div>
        </footer>
    );
};
