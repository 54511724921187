import React from 'react';
import { IconsProps } from './Icons.type';

export const ArrowRightIcon1: React.FC<IconsProps> = (props) => {
    const { className, isDisabled } = props;
    const strokeColor = isDisabled ? '#E0E0E0' : '#333333';

    return (
        <svg
            width="16"
            height="10"
            viewBox="0 0 16 10"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className={className}
        >
            <path
                d="M1 5l15 0 M9 10L15 5L10 1"
                stroke={strokeColor}
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
};
