import React, { FunctionComponent } from 'react';
import ReactPaginate from 'react-paginate';

import { ArrowRightIcon1 } from '../../Icons';

import styles from './Pagination.module.scss';
import { PaginationProps } from './Pagination.type';

export const Pagination: FunctionComponent<PaginationProps> = ({
    totalPages,
    initialPage,
    handleChangePage,
}) => (
    <ReactPaginate
        previousLabel={<ArrowRightIcon1 className={styles.leftIcon} />}
        nextLabel={<ArrowRightIcon1 className={styles.rightIcon} />}
        breakLabel="..."
        breakClassName={styles.paginationBreak}
        pageCount={totalPages}
        marginPagesDisplayed={1}
        pageRangeDisplayed={4}
        onPageChange={(selectedItem) => handleChangePage(selectedItem.selected)}
        containerClassName={styles.pagination}
        activeClassName={styles.paginationActive}
        initialPage={initialPage}
    />
);
