import React from 'react';
import { IconsProps } from './Icons.type';
import styles from './Icons.module.scss';

export const CloseIcon: React.FC<IconsProps> = ({
    fill = '#A0A4B5',
    onClick,
    opacity = '0.5',
}) => {
    return (
        <svg
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className={styles.closeIcon}
            onClick={onClick}
        >
            <g opacity={opacity}>
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M15.7397 0.26035C16.0868 0.607482 16.0868 1.1703 15.7397 1.51743L1.51743 15.7397C1.1703 16.0868 0.607482 16.0868 0.26035 15.7397C-0.0867832 15.3925 -0.0867832 14.8297 0.26035 14.4826L14.4826 0.26035C14.8297 -0.0867832 15.3925 -0.0867832 15.7397 0.26035Z"
                    fill={fill}
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M0.26035 0.26035C0.607482 -0.0867832 1.1703 -0.0867832 1.51743 0.26035L15.7397 14.4826C16.0868 14.8297 16.0868 15.3925 15.7397 15.7397C15.3925 16.0868 14.8297 16.0868 14.4826 15.7397L0.26035 1.51743C-0.0867832 1.1703 -0.0867832 0.607482 0.26035 0.26035Z"
                    fill={fill}
                />
            </g>
        </svg>
    );
};
