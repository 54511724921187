export const isBrowser = typeof window !== 'undefined';

export const isIE11 = isBrowser && /Trident\//.test(window.navigator.userAgent);

if (isBrowser && isIE11) {
    // @ts-ignore
    window.__defineGetter__('scrollY', () => window.pageYOffset);
    // @ts-ignore
    window?.__defineGetter__('scrollX', () => window.pageXOffset);
}

export const isProduction = isBrowser && window.location.hostname === 'estp.ru';
