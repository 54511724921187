import { useField } from 'formik';
import { FC } from 'react';
import React from 'react';
import { TextArea } from './TextArea';
import { Props as TextAreaProps } from './TextArea';

interface Props extends TextAreaProps {
    name: string;
}

export const FormTextArea: FC<Props> = ({ name, ...props }) => {
    const [field, meta] = useField(name);

    return (
        <TextArea
            {...field}
            {...props}
            hasError={Boolean(meta.touched && meta.error)}
            errorMessage={meta.error}
        />
    );
};
