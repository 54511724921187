import React from 'react';

import { IconsProps } from './Icons.type';

export const CartIcon: React.FC<IconsProps> = (props) => (
    <svg
        width="44"
        height="48"
        viewBox="0 0 44 48"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <path
            d="M43.2062 29.6407V12.4168L9.27561 6.29045V4.17299C9.27561 1.87203 7.40368 0 5.10272 0H0.792969V2.82755H5.10262C5.84448 2.82755 6.44807 3.43114 6.44807 4.17299V30.2812C6.44807 33.2952 8.90012 35.7473 11.9142 35.7473H13.0457V38.9695C12.468 38.7163 11.8307 38.5748 11.1606 38.5748C8.56213 38.5748 6.44807 40.6889 6.44807 43.2874C6.44807 45.8859 8.56213 48 11.1606 48C13.6002 48 15.6124 46.1367 15.8496 43.7587H15.8732V35.7473H40.3787V38.9695C39.801 38.7163 39.1637 38.5748 38.4936 38.5748C35.8951 38.5748 33.781 40.6889 33.781 43.2874C33.781 45.8859 35.8951 48 38.4936 48C40.9331 48 42.9454 46.1367 43.1825 43.7587H43.2062V32.9197H11.9142C10.4592 32.9197 9.27561 31.7361 9.27561 30.2812V29.1694L43.2062 29.6407ZM11.1606 45.1724C10.1212 45.1724 9.27561 44.3268 9.27561 43.2874C9.27561 42.248 10.1212 41.4024 11.1606 41.4024C12.2001 41.4024 13.0457 42.248 13.0457 43.2874C13.0457 44.3268 12.2001 45.1724 11.1606 45.1724ZM38.4936 45.1724C37.4542 45.1724 36.6086 44.3268 36.6086 43.2874C36.6086 42.248 37.4542 41.4024 38.4936 41.4024C39.533 41.4024 40.3787 42.248 40.3787 43.2874C40.3787 44.3268 39.533 45.1724 38.4936 45.1724ZM23.4134 26.538L17.7583 26.4594V10.6953L23.4134 11.7163V26.538ZM26.2409 12.2269L31.4247 13.1629V26.6493L26.2409 26.5773V12.2269ZM14.9307 26.4201L9.27561 26.3416V9.16371L14.9307 10.1847V26.4201ZM40.3787 26.7736L34.2523 26.6885V13.6734L40.3787 14.7795V26.7736Z"
            fill="white"
        />
    </svg>
);
