import { FC } from 'react';
import React from 'react';
import { SvgIcon } from '../../base-components/SvgIcon';

export const BiddingIcon: FC = () => (
    <SvgIcon width={22} height={22} noFill>
        <path
            d="M18.3422 16.9674L13.8047 12.4299"
            strokeWidth="1.5"
            strokeMiterlimit="10"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M13.8051 12.4299L10.5601 15.6749C9.84506 16.3899 8.68089 16.3899 7.96589 15.6749L4.07922 11.7882C3.36422 11.0732 3.36422 9.90909 4.07922 9.19409L10.5601 2.71325C11.2751 1.99825 12.4392 1.99825 13.1542 2.71325L17.0409 6.59994C17.7559 7.31494 17.7559 8.47909 17.0409 9.19409L13.8051 12.4299Z"
            strokeWidth="1.5"
            strokeMiterlimit="10"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M1.83398 19.25H7.33399"
            strokeWidth="1.5"
            strokeMiterlimit="10"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M6.01367 7.26001L12.4945 13.7408"
            strokeWidth="1.5"
            strokeMiterlimit="10"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </SvgIcon>
);
