import React from 'react';
import { IconsProps } from './Icons.type';

export const ChevronRightIcon: React.FC<IconsProps> = ({
    className,
    fill = '#2D9CDB',
}) => {
    return (
        <svg
            width="8"
            height="14"
            viewBox="0 0 8 14"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className={className}
        >
            <path
                d="M1 13L7 7L1 1"
                stroke={fill}
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
};
