import clsx from 'clsx';
import React from 'react';
import styles from './Sphere.module.scss';
import { SphereProps } from './Sphere.type';

export const Sphere: React.FC<SphereProps> = (props) => {
    const { color, zIndex, transform } = props;

    return (
        <figure
            className={clsx(styles.ellipse, styles[color], styles.big)}
            style={{ zIndex, transform }}
        >
            <figure
                className={clsx(styles.ellipse, styles[color], styles.mini)}
            />
        </figure>
    );
};
