import { useMemo } from 'react';
import React from 'react';
import { Breadcrumbs } from './_shared';
import { useBreadcrumbsContext } from './_shared/Breadcrumbs/BreadcrumbsContext';
import { Footer } from './Footer';
import { Header } from './Header';
import styles from './Layout.module.scss';
import { LayoutProviderProps } from './LayoutContext';
import { LayoutProvider } from './LayoutContext';
import { useUserContext } from './UserContext';
import Meta from './Meta';

export const Layout: React.FC = ({ children }) => {
    const { user, logout } = useUserContext();
    const { breadcrumbs } = useBreadcrumbsContext();
    const layoutParams = useMemo<LayoutProviderProps['initialValues']>(
        () => ({
            embedded: false,
        }),
        []
    );

    return (
        <LayoutProvider initialValues={layoutParams}>
            <Meta />
            <div className={styles.wrapper}>
                <Header loggedInUser={user} logout={logout} />
                <section className={styles.section}>
                    <Breadcrumbs breadcrumbs={breadcrumbs} />
                    {children}
                </section>
                <Footer />
            </div>
        </LayoutProvider>
    );
};
