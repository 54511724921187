/* eslint-disable max-len */
import React from 'react';

import { IconsProps } from './Icons.type';

export const DownloadIcon: React.FC<IconsProps> = ({ fill = '#2D9CDB' }) => {
    return (
        <svg
            width="18"
            height="20"
            viewBox="0 0 18 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M9 0C9.55229 0 10 0.447715 10 1V11.2426L12.5355 8.70707C12.9261 8.31655 13.5592 8.31655 13.9497 8.70707C14.3403 9.0976 14.3403 9.73076 13.9497 10.1213L9.88388 14.1871C9.39573 14.6753 8.60427 14.6753 8.11612 14.1871L4.05025 10.1213C3.65973 9.73076 3.65973 9.0976 4.05025 8.70707C4.44078 8.31655 5.07394 8.31655 5.46447 8.70707L8 11.2426V1C8 0.447715 8.44771 0 9 0Z"
                fill={fill}
            />
            <path
                d="M1 13C1.55228 13 2 13.4477 2 14V18H16V14C16 13.4477 16.4477 13 17 13C17.5523 13 18 13.4477 18 14V18C18 19.1046 17.1046 20 16 20H2C0.89543 20 0 19.1046 0 18V14C0 13.4477 0.447715 13 1 13Z"
                fill={fill}
            />
        </svg>
    );
};
