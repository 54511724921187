import clsx from 'clsx';
import Link from 'next/link';
import React from 'react';
import { LoggedInUser } from '../../types/entities';
import { ThreeLinesIcon } from '../Icons';
import { AboutIcon } from '../Icons';
import { TariffsIcon } from '../Icons';
import { CustomersIcon } from '../Icons';
import { ContractorIcon } from '../Icons';
import { ServicesIcon } from '../Icons';
import { SectionsIcon } from '../Icons';
import { BiddingIcon } from '../Icons';
import { LogoIcon } from '../Icons';
import { rootLinks } from '../Links';
import styles from './Header.module.scss';
import useModalState from '../_hooks/useModalState';
import { MobileMenu } from './MobileMenu/MobileMenu';
import { getHref } from '../../utils/getHref';

interface Props {
    loggedInUser: LoggedInUser | null;
    logout: () => Promise<void>;
}

export const Header: React.FC<Props> = ({ loggedInUser, logout }) => {
    const [isMobileMenuVisible, showMobileMenu, hideMobileMenu] =
        useModalState();
    return (
        <header className={styles.wrapper}>
            <div className={styles.container}>
                <Link href={rootLinks.main.url}>
                    <a className={styles.logo}>
                        <LogoIcon />
                    </a>
                </Link>
                <ul className={styles.menu}>
                    <li className={styles.menuItem}>
                        <Link href={rootLinks.purchases.url}>
                            <a className={styles.menuLink}>
                                <BiddingIcon />
                                <div>Закупки</div>
                            </a>
                        </Link>
                    </li>
                    <li className={styles.menuItem}>
                        <Link href={rootLinks.sections.url}>
                            <a className={styles.menuLink}>
                                <SectionsIcon />
                                <div>Секции ЭТП</div>
                            </a>
                        </Link>
                    </li>
                    <li className={styles.menuItem}>
                        <Link href={`${rootLinks.services.url}/customers`}>
                            <a className={styles.menuLink}>
                                <ServicesIcon />
                                <div>Сервисы</div>
                            </a>
                        </Link>
                    </li>
                    <li className={styles.menuItem}>
                        <Link href={rootLinks.customers.url}>
                            <a className={styles.menuLink}>
                                <CustomersIcon />
                                <div>Заказчикам</div>
                            </a>
                        </Link>
                    </li>
                    <li className={styles.menuItem}>
                        <Link href={rootLinks.members.url}>
                            <a className={styles.menuLink}>
                                <ContractorIcon />
                                <div>Поставщикам</div>
                            </a>
                        </Link>
                    </li>
                    <li className={styles.menuItem}>
                        <Link href={`${rootLinks.tariffs.url}/licenses`}>
                            <a className={styles.menuLink}>
                                <TariffsIcon />
                                <div>Тарифы</div>
                            </a>
                        </Link>
                    </li>
                    <li className={styles.menuItem}>
                        <Link href={rootLinks.info.url}>
                            <a className={styles.menuLink}>
                                <AboutIcon />
                                <div>О площадке</div>
                            </a>
                        </Link>
                    </li>
                </ul>

                <div className={styles.phone}>
                    <a href="tel:88005552083">
                        <span>8 800 555 20 83</span>
                    </a>
                </div>

                <div className={clsx(styles.delimiter, styles.first)} />

                {loggedInUser ? (
                    <Link href={getHref('/personal')}>
                        <a className={styles.login}>
                            <span>Войти</span>
                        </a>
                    </Link>
                ) : (
                    <Link href={rootLinks.auth.url}>
                        <a className={styles.login}>
                            <span>Вход в кабинет</span>
                        </a>
                    </Link>
                )}

                <div className={clsx(styles.delimiter, styles.second)} />

                <div className={styles.lines} onClick={showMobileMenu}>
                    <ThreeLinesIcon />
                </div>

                {isMobileMenuVisible && (
                    <MobileMenu
                        loggedInUser={loggedInUser}
                        onHide={hideMobileMenu}
                    />
                )}
            </div>
        </header>
    );
};
