import { noop } from 'lodash';
import { useState } from 'react';
import { useMemo } from 'react';
import { useContext } from 'react';
import { FC } from 'react';
import React from 'react';
import { createContext } from 'react';

interface LayoutContextType {
    embedded: boolean;
    setEmbedded: (value: boolean) => void;
}

export const LayoutContext = createContext<LayoutContextType>({
    embedded: false,
    setEmbedded: noop,
});

export interface LayoutProviderProps {
    initialValues: Pick<LayoutContextType, 'embedded'>;
}

export const LayoutProvider: FC<LayoutProviderProps> = ({
    initialValues,
    children,
}) => {
    const [embedded, setEmbedded] = useState<boolean>(initialValues.embedded);

    const value = useMemo(
        () => ({
            embedded,
            setEmbedded,
        }),
        [embedded]
    );

    return (
        <LayoutContext.Provider value={value}>
            {children}
        </LayoutContext.Provider>
    );
};

export const useLayoutContext = () => useContext(LayoutContext);
