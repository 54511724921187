import Link from 'next/link';
import { useRouter } from 'next/router';
import { useEffect, useRef } from 'react';
import { useClickAway, usePrevious } from 'react-use';
import { LoggedInUser } from '../../../types/entities';
import { getHref } from '../../../utils/getHref';
import {
    AboutIcon,
    BiddingIcon,
    CloseIcon,
    ContractorIcon,
    CustomersIcon,
    SectionsIcon,
    ServicesIcon,
    TariffsIcon,
} from '../../Icons';
import { rootLinks } from '../../Links';
import styles from './MobileMenu.module.scss';

export type MobileMenuProps = {
    loggedInUser: LoggedInUser | null;
    onHide: () => void;
};

export const MobileMenu: React.FC<MobileMenuProps> = ({
    loggedInUser,
    onHide,
}) => {
    const router = useRouter();
    const previousRouter = usePrevious(router);
    const ref = useRef(null);
    useClickAway(ref, () => {
        onHide();
    });
    useEffect(() => {
        if (previousRouter !== undefined) {
            onHide();
        }
    }, [onHide, previousRouter]);
    return (
        <div className={styles.wrapper}>
            <div className={styles.container} ref={ref}>
                <CloseIcon fill="#333333" onClick={onHide} opacity="1" />
                {loggedInUser ? (
                    <Link href={getHref('/personal')}>
                        <a className={styles.login}>
                            <h1>Вход в кабинет</h1>
                        </a>
                    </Link>
                ) : (
                    <Link href={rootLinks.auth.url}>
                        <a className={styles.login}>
                            <h1>Вход в кабинет</h1>
                        </a>
                    </Link>
                )}

                <ul className={styles.mobileMenu}>
                    <li className={styles.menuItem}>
                        <Link href={rootLinks.purchases.url}>
                            <a className={styles.menuLink}>
                                <BiddingIcon />
                                <div>Закупки</div>
                            </a>
                        </Link>
                    </li>
                    <li className={styles.menuItem}>
                        <Link href={rootLinks.sections.url}>
                            <a className={styles.menuLink}>
                                <SectionsIcon />
                                <div>Секции ЭТП</div>
                            </a>
                        </Link>
                    </li>
                    <li className={styles.menuItem}>
                        <Link href={`${rootLinks.services.url}/customers`}>
                            <a className={styles.menuLink}>
                                <ServicesIcon />
                                <div>Сервисы</div>
                            </a>
                        </Link>
                    </li>
                    <li className={styles.menuItem}>
                        <Link href={rootLinks.customers.url}>
                            <a className={styles.menuLink}>
                                <CustomersIcon />
                                <div>Заказчикам</div>
                            </a>
                        </Link>
                    </li>
                    <li className={styles.menuItem}>
                        <Link href={rootLinks.members.url}>
                            <a className={styles.menuLink}>
                                <ContractorIcon />
                                <div>Поставщикам</div>
                            </a>
                        </Link>
                    </li>
                    <li className={styles.menuItem}>
                        <Link href={`${rootLinks.tariffs.url}/licenses`}>
                            <a className={styles.menuLink}>
                                <TariffsIcon />
                                <div>Тарифы</div>
                            </a>
                        </Link>
                    </li>
                    <li className={styles.menuItem}>
                        <Link href={rootLinks.info.url}>
                            <a className={styles.menuLink}>
                                <AboutIcon />
                                <div>О площадке</div>
                            </a>
                        </Link>
                    </li>
                </ul>

                <div className={styles.phone}>
                    <a href="tel:88005552083">
                        <span>8 800 555 20 83</span>
                    </a>
                </div>
            </div>
        </div>
    );
};
