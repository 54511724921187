import clsx from 'clsx';
import { TextareaHTMLAttributes } from 'react';
import { FC } from 'react';
import React from 'react';
import { ErrorMessage } from './ErrorMessage';
import styles from './FormTextArea.module.scss';

export interface Props extends TextareaHTMLAttributes<HTMLTextAreaElement> {
    label?: string;
    hasError?: boolean;
    errorMessage?: string;
    inputRef?: React.LegacyRef<HTMLTextAreaElement>;
}

export const TextArea: FC<Props> = ({
    label,
    placeholder,
    className,
    inputRef,
    hasError,
    errorMessage,
    ...props
}) => {
    return (
        <div className={className}>
            <label className={clsx(styles.field, hasError && styles.error)}>
                <textarea
                    className={clsx(styles.textarea, hasError && styles.error)}
                    placeholder={placeholder || label || ''}
                    ref={inputRef}
                    {...props}
                />
            </label>

            {hasError && (
                <ErrorMessage className={styles.errorMessage}>
                    {errorMessage}
                </ErrorMessage>
            )}
        </div>
    );
};
