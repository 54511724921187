import React, { useEffect, useState } from 'react';
import { changeTransformValueAndDirection } from '../../../utils';
import { getRandomElementFromArray } from '../../../utils';
import { HOC } from '../../../utils';
import { SphereProps } from './Sphere.type';

type InjectedProps = Pick<SphereProps, 'transform'>;
type OwnProps = Pick<SphereProps, 'zIndex' | 'color'> & {
    step: number;
    maxMove: number;
};

export const withState =
    (): HOC<InjectedProps, OwnProps> => (BaseComponent) => (props) => {
        const { step, maxMove, ...rest } = props;
        const [xTransform, setXTransform] = useState<number>(0);
        const [yTransform, setYTransform] = useState<number>(0);
        const [xDirection, setXDirection] = useState<'up' | 'down'>('up');
        const [yDirection, setYDirection] = useState<'up' | 'down'>('up');
        const directions: ('up' | 'down')[] = ['up', 'down'];

        useEffect(() => {
            setXTransform(0);
            setYTransform(0);
            setXDirection(getRandomElementFromArray(directions));
            setYDirection(getRandomElementFromArray(directions));

            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [step, maxMove]);

        useEffect(() => {
            const direction = getRandomElementFromArray(directions);

            if (direction !== yDirection) {
                setYDirection(direction);
            }

            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [xDirection]);

        useEffect(() => {
            const direction = getRandomElementFromArray(directions);

            if (direction !== yDirection) {
                setXDirection(direction);
            }

            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [yDirection]);

        useEffect(() => {
            const xTimeout = setTimeout(() => {
                const payload = changeTransformValueAndDirection(
                    xTransform,
                    xDirection,
                    step,
                    maxMove
                );

                setXTransform(payload.cord);
                if (xDirection !== payload.objectGrow) {
                    setXDirection(payload.objectGrow);
                }
            }, 5);

            return () => clearTimeout(xTimeout);

            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [xTransform]);

        useEffect(() => {
            const yTimeout = setTimeout(() => {
                const payload = changeTransformValueAndDirection(
                    yTransform,
                    yDirection,
                    step,
                    maxMove
                );

                setYTransform(payload.cord);
                if (yDirection !== payload.objectGrow) {
                    setYDirection(payload.objectGrow);
                }
            }, 2);

            return () => clearTimeout(yTimeout);

            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [yTransform]);

        const injectedProps: InjectedProps = {
            transform: `translate(${xTransform}px, ${yTransform}px)`,
        };

        return <BaseComponent {...(rest as any)} {...injectedProps} />;
    };
