import clsx from 'clsx';
import { FC } from 'react';
import styles from './ErrorMessage.module.scss';

interface Props {
    className?: string;
}

export const ErrorMessage: FC<Props> = ({ className, children }) => {
    return <div className={clsx(styles.message, className)}>{children}</div>;
};
