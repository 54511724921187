import { slice, take } from 'lodash';
import Link from 'next/link';
import React, { useCallback, useMemo, useState } from 'react';
import { SectionsOrgResponse } from '../../../api/fetchSectionOrgs';
import { ChevronRightIcon } from '../../Icons';
import { rootLinks } from '../../Links';
import { Company } from '../Company';
import styles from './CompaniesBlock.module.scss';

interface CompaniesBlockProps {
    isMembersPage?: boolean;
    orgSections: SectionsOrgResponse;
    title: string;
}

export const CompaniesBlock: React.FC<CompaniesBlockProps> = ({
    title,
    orgSections,
    isMembersPage = false,
}) => {
    const alwaysVisibleOrgSections = useMemo(
        () => take(orgSections, 10),
        [orgSections]
    );
    const hiddenOrgSections = useMemo(
        () => slice(orgSections, 10),
        [orgSections]
    );
    const showShowMore = !isMembersPage && orgSections.length > 10;
    const [isShowAll, setIsShowAll] = useState(false);
    const viewAllHandler = useCallback(() => {
        setIsShowAll(!isShowAll);
    }, [isShowAll]);
    return (
        <div className={styles.container}>
            <div className={styles.title}>{title}</div>
            <div className={styles.list}>
                {alwaysVisibleOrgSections.map((orgSection) => (
                    <div key={orgSection.id} className={styles.item}>
                        <Company orgSection={orgSection} />
                    </div>
                ))}
                <div className={styles.pagination}>
                    {isMembersPage && (
                        <Link
                            href={{
                                pathname: rootLinks.companies.url,
                            }}
                        >
                            <a className={styles.link}>
                                <span>посмотреть всех</span>
                                <ChevronRightIcon />
                            </a>
                        </Link>
                    )}
                    {showShowMore && (
                        <a className={styles.link} onClick={viewAllHandler}>
                            {!isShowAll && <span>посмотреть всех</span>}
                            {isShowAll && <span>свернуть</span>}
                            <ChevronRightIcon />
                        </a>
                    )}
                </div>
            </div>
            {isShowAll && (
                <div className={styles.list}>
                    {hiddenOrgSections.map((orgSection) => (
                        <div key={orgSection.id} className={styles.item}>
                            <Company orgSection={orgSection} />
                        </div>
                    ))}
                </div>
            )}
        </div>
    );
};
