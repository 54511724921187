import clsx from 'clsx';
import Link from 'next/link';
import React from 'react';
import styles from './Breadcrumbs.module.scss';
import { BreadcrumbsProps } from './Breadcrumbs.type';

export const Breadcrumbs: React.FC<BreadcrumbsProps> = ({ breadcrumbs }) => {
    if (breadcrumbs.length < 2) {
        return null;
    }

    return (
        <div className={clsx(styles.container)}>
            {breadcrumbs.map((breadcrumb) =>
                breadcrumb.href ? (
                    <Link key={breadcrumb.title} href={breadcrumb.href}>
                        <a
                            className={styles.link}
                        >{`${breadcrumb.title} > `}</a>
                    </Link>
                ) : (
                    <span key={breadcrumb.title} className={styles.text}>
                        {breadcrumb.title}
                    </span>
                )
            )}
        </div>
    );
};
