import React from 'react';

import { IconsProps } from './Icons.type';

export const HammerIcon: React.FC<IconsProps> = (props) => (
    <svg
        width="48"
        height="48"
        viewBox="0 0 48 48"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <g clipPath="url(#clip0_1384:99)">
            <path
                d="M25.1981 25.9894L23.2014 27.9861L29.1913 33.976L41.171 21.9963L35.1811 16.0064L33.1845 18.003L23.2014 8.01994L25.198 6.02334L19.2081 0.0334473L7.22849 12.0132L13.2183 18.003L15.215 16.0063L17.2116 18.003L1.23859 33.976C-0.412864 35.6275 -0.412864 38.3145 1.23859 39.9659C2.89005 41.6174 5.57703 41.6174 7.22839 39.9659L23.2014 23.9929L25.1981 25.9894ZM11.2217 12.0132L19.2082 4.02665L21.2049 6.02334L13.2183 14.0098L11.2217 12.0132ZM35.1811 19.9996L37.1778 21.9963L29.1913 29.9829L27.1946 27.9862L27.1947 27.9861V27.9862L35.1811 19.9996ZM17.2116 14.0098L21.2049 10.0165L31.188 19.9995L27.1947 23.9928C25.7196 22.5179 18.1515 14.9498 17.2116 14.0098ZM3.23519 37.9693C2.68345 37.4176 2.68345 36.5244 3.23519 35.9726L4.85531 34.3525L6.852 36.3491L5.23179 37.9693C4.68005 38.521 3.78703 38.521 3.23519 37.9693ZM8.8486 34.3525L6.85191 32.3559L19.2081 19.9996L21.2048 21.9963L8.8486 34.3525Z"
                fill="white"
            />
            <path
                d="M44.7208 45.1403L41.8972 38.0812H23.0437L20.2201 45.1403H16.9404V47.964H48.0005V45.1403H44.7208ZM23.2612 45.1403L24.9553 40.9048H39.9855L41.6797 45.1403H23.2612Z"
                fill="white"
            />
        </g>
        <defs>
            <clipPath id="clip0_1384:99">
                <rect width="48" height="48" fill="white" />
            </clipPath>
        </defs>
    </svg>
);
