export interface LinkType {
    url: string;
    name: string;
}

export const rootLinks = Object.freeze({
    main: {
        url: '/',
        name: 'Главная',
    },
    purchases: {
        url: '/purchases',
        name: 'Закупки',
    },
    info: {
        url: '/info/about',
        name: 'О площадке',
    },
    news: {
        url: '/info/news',
        name: 'Новости',
    },
    documents: {
        url: '/info/documents',
        name: 'Документы',
    },
    programs: {
        url: '/info/programs',
        name: 'Программные модули',
    },
    companies: {
        url: '/info/companies',
        name: 'Нам доверяют',
    },
    requisites: {
        url: '/info/requisites',
        name: 'Реквизиты',
    },
    contacts: {
        url: '/info/contacts',
        name: 'Контакты',
    },
    members: {
        url: '/members',
        name: 'Участникам',
    },
    customers: {
        url: '/customers',
        name: 'Заказчикам',
    },
    sections: {
        url: '/sections',
        name: 'Секции ЭТП',
    },
    services: {
        url: '/services',
        name: 'Сервисы',
    },
    tariffs: {
        url: '/tariffs',
        name: 'Тарифы',
    },
    auth: {
        url: '/auth/login',
        name: 'Auth',
    },
    faq: {
        url: '/faq',
        name: 'FAQ',
    },
});
