type Tags = {
    [key: string]: {
        title: string;
        description: string;
        url: string;
        headings?: string;
        keywords?: string;
    };
};

export const metaTags: Tags = {
    main: {
        title: 'ЕСТП - Электронная торговая площадка',
        description:
            'Официальный сайт электронной торговой площадки «ЕСТП». Проведение онлайн торгов и аукционов, закупки по 223-ФЗ, малые закупки, коммерческие тендеры. Телефон для справок +7 (800) 555-20-83',
        url: 'https://estp.ru',
        keywords:
            'ЕСТП, Электронная торговая площадка, Закупки, Тендеры, 223-Ф3, Предквалификация, Аккредитация, Коммерческие закупки, Закупки малого объема, ',
    },
    purchases: {
        title: 'Поиск торгов и аукционов, найти закупку по номеру',
        description:
            'Поиск торгов и аукционов на официальном сайте «ЕСТП». Проведение онлайн торгов и аукционов, закупки по 223-ФЗ, малые закупки, коммерческие тендеры. Телефон для справок +7 (800) 555-20-83',
        url: 'https://estp.ru/purchases',
    },
    sections: {
        title: 'Услуги торговой электронной площадки - ЕСТП',
        description:
            'Услуги по электронным торгам на официальном сайте «ЕСТП». Проведение онлайн торгов и аукционов, закупки по 223-ФЗ, малые закупки, коммерческие тендеры. Телефон для справок +7 (800) 555-20-83',
        url: 'https://estp.ru/sections',
        headings: '',
    },
    services: {
        title: 'Услуги торговой электронной площадки - ЕСТП',
        description:
            'Услуги по электронным торгам на официальном сайте «ЕСТП». Проведение онлайн торгов и аукционов, закупки по 223-ФЗ, малые закупки, коммерческие тендеры. Телефон для справок +7 (800) 555-20-83',
        url: 'https://estp.ru/services',
        headings: '',
    },
    'fz-223': {
        title: 'Закупки в соответствии с 223 Ф3 - ЕСТП',
        description:
            'Проведение закупок по 223 Ф3 на официальном сайте «ЕСТП». Проведение онлайн торгов и аукционов, малые закупки, коммерческие тендеры. Телефон для справок +7 (800) 555-20-83',
        url: 'https://estp.ru/purchases?section=fz-223',
        headings: 'Закупки по 223-Ф3',
    },
    prequalification: {
        title: 'Предквалификация - ЕСТП',
        description:
            'Проведение закупок по 223 Ф3 на официальном сайте «ЕСТП». Проведение онлайн торгов и аукционов, малые закупки, коммерческие тендеры. Телефон для справок +7 (800) 555-20-83',
        url: 'https://estp.ru/purchases?section=fz-223',
        headings: 'Предквалификация',
    },
    accreditation: {
        title: 'Аккредитация - ЕСТП',
        description:
            'Проведение закупок по 223 Ф3 на официальном сайте «ЕСТП». Проведение онлайн торгов и аукционов, малые закупки, коммерческие тендеры. Телефон для справок +7 (800) 555-20-83',
        url: 'https://estp.ru/purchases?section=accreditation',
        headings: 'Аккредитация',
    },
    commercial: {
        title: 'Коммерческие тендеры, закупки коммерческих организаций',
        description:
            'Проведение коммерческих тендеров на официальном сайте «ЕСТП». Проведение онлайн торгов и аукционов, закупки по 223-ФЗ, малые закупки. Телефон для справок +7 (800) 555-20-83',
        url: 'https://estp.ru/purchases?section=commercial',
        headings: 'Коммерческие закупки',
    },
    'e-shop': {
        title: 'Электронный магазин малых закупок - ЕСТП',
        description:
            ' Электронный магазин закупок малого объема на официальном сайте «ЕСТП». Проведение онлайн торгов и аукционов, закупки по 223-ФЗ, коммерческие тендеры. Телефон для справок +7 (800) 555-20-83',
        url: 'https://estp.ru/purchases?section=e-shop',
        headings: 'Закупки малого объема',
    },
    'construction-materials': {
        title: 'Тендеры на строительные материалы - ЕСТП',
        description:
            'Тендеры и закупки строительных материалов на официальном сайте «ЕСТП». Проведение онлайн торгов и аукционов, закупки по 223-ФЗ, малые закупки, коммерческие тендеры.. Телефон для справок +7 (800) 555-20-83',
        url: 'https://estp.ru/purchases?section=construction-materials',
        headings: 'Тендеры (закупки) на поставку строительных материалов',
    },
    'construction-services': {
        title: 'Тендеры на строительно-монтажные работы',
        description:
            'Строительные тендеры на официальном сайте «ЕСТП». Проведение онлайн торгов и аукционов, закупки по 223-ФЗ, малые закупки, коммерческие тендеры. Телефон для справок +7 (800) 555-20-83',
        url: 'https://estp.ru/purchases?section=construction-services',
        headings: 'Строительные тендеры (закупки)',
    },
    food: {
        title: 'Тендеры на продукты питания - ЕСТП',
        description:
            'Тендеры, закупка продуктов питания для организаций на официальном сайте «ЕСТП». Проведение онлайн торгов и аукционов, закупки по 223-ФЗ, малые закупки, коммерческие тендеры. Телефон для справок +7 (800) 555-20-83',
        url: 'https://estp.ru/purchases?section=food',
        headings: 'Тендеры на поставку продуктов питания',
    },
    logistics: {
        title: 'Тендеры на перевозку грузов и логистику - ЕСТП',
        description:
            'Закупки, тендеры на грузоперевозки на официальном сайте «ЕСТП». Проведение онлайн торгов и аукционов, закупки по 223-ФЗ, малые закупки, коммерческие тендеры. Телефон для справок +7 (800) 555-20-83',
        url: 'https://estp.ru/purchases?section=logistics',
        headings: 'Тендеры на оказание транспортных услуг',
    },
    medicine: {
        title: 'Медицинские тендеры, закупки в медицинские учреждения',
        description:
            'Медицинские тендеры, закупки в медицинской организации на официальном сайте «ЕСТП». Проведение онлайн торгов и аукционов, закупки по 223-ФЗ, малые закупки, коммерческие тендеры. Телефон для справок +7 (800) 555-20-83',
        url: 'https://estp.ru/purchases?section=medicine',
        headings: 'Тендеры на поставку медицинских препаратов и оказание услуг',
    },
    members: {
        title: 'Поставщикам электронных торгов - ЕСТП',
        description:
            'Поставщикам электронного аукциона, торгов на официальном сайте «ЕСТП». Проведение онлайн торгов и аукционов, закупки по 223-ФЗ, малые закупки, коммерческие тендеры. Телефон для справок +7 (800) 555-20-83',
        url: 'https://estp.ru/members',
    },
    customers: {
        title: 'Заказчикам электронных торгов - ЕСТП',
        description:
            'Заказчикам электронного аукциона, торгов на официальном сайте «ЕСТП». Проведение онлайн торгов и аукционов, закупки по 223-ФЗ, малые закупки, коммерческие тендеры. Телефон для справок +7 (800) 555-20-83',
        url: 'https://estp.ru/customers',
    },
    tariffs: {
        title: 'Тарифы электронной торговой площадки - ЕСТП',
        description:
            'Тарифы ЭТП на официальном сайте «ЕСТП». Проведение онлайн торгов и аукционов, закупки по 223-ФЗ, малые закупки, коммерческие тендеры. Телефон для справок +7 (800) 555-20-83',
        url: 'https://estp.ru/tariffs',
    },
    about: {
        title: 'О площадке - ЕСТП',
        description:
            'Информация о электронной торговой площадки «ЕСТП». Телефон для справок +7 (800) 555-20-83',
        url: 'https://estp.ru/info/about',
    },
    news: {
        title: 'Новости площадки - ЕСТП',
        description:
            'Новости электронной торговой площадки «ЕСТП». Телефон для справок +7 (800) 555-20-83',
        url: 'https://estp.ru/info/news?page=1',
    },
    documents: {
        title: 'Документы - ЕСТП',
        description:
            'Документы электронной торговой площадки «ЕСТП». Телефон для справок +7 (800) 555-20-83',
        url: 'https://estp.ru/info/documents',
    },
    programs: {
        title: 'Программные модули - ЕСТП',
        description:
            'Программные модули электронной торговой площадки «ЕСТП». Телефон для справок +7 (800) 555-20-83',
        url: 'https://estp.ru/info/programs',
    },
    requisites: {
        title: 'Реквизиты - ЕСТП',
        description:
            'Реквизиты электронной торговой площадки «ЕСТП». Телефон для справок +7 (800) 555-20-83',
        url: 'https://estp.ru/info/requisites',
    },
    contacts: {
        title: 'Контакты - ЕСТП',
        description:
            'Контактная информация электронной торговой площадки «ЕСТП». Телефон для справок +7 (800) 555-20-83',
        url: 'https://estp.ru/info/contacts',
    },
    orgSection: {
        title: ' - ЕСТП',
        description:
            ' на официальном сайте «ЕСТП». Проведение онлайн торгов и аукционов, закупки по 223-ФЗ, малые закупки, коммерческие тендеры. Телефон для справок +7 (800) 555-20-83',
        url: 'https://estp.ru/purchases?sectionOrg=',
    },
};
