import clsx from 'clsx';
import React from 'react';
import ReactMarkdown from 'react-markdown';
import formatMomentDate from '../../../utils/formatMomentDate';
import { ChevronRightIcon } from '../../Icons/ChevronRightIcon';
import styles from './NewsCard.module.scss';
import { NewsCardProps } from './NewsCard.type';
import { rootLinks } from '../../Links';
import Link from 'next/link';

export const NewsCard: React.FC<NewsCardProps> = ({ item, isOpacity }) => {
    const isTypeLocal = item?.type === 'local';
    return (
        <div className={styles.container}>
            <div
                className={clsx(
                    styles.header,
                    styles[item?.color],
                    isOpacity && styles.opacity
                )}
            >
                {isTypeLocal ? (
                    <>
                        <img src="/assets/news-card/judge.svg" alt="" />
                        <span>Новости площадки</span>
                    </>
                ) : (
                    <>
                        <img src="/assets/news-card/global.svg" alt="" />
                        <span>Новости отрасли</span>
                    </>
                )}
            </div>
            <div className={styles.content}>
                <ReactMarkdown
                    components={{
                        h1: 'div',
                    }}
                >
                    {item?.text}
                </ReactMarkdown>
            </div>
            <div className={styles.footer}>
                <div className={styles.date}>
                    {formatMomentDate(item.createdDate, 'dateLong')}
                </div>
                <Link href={`/info/news/${item.id}` || rootLinks.news.url}>
                    <a className={styles.link}>
                        <ChevronRightIcon fill="#333333" />
                    </a>
                </Link>
            </div>
        </div>
    );
};
