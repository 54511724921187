import noop from 'lodash/noop';
import { useEffect, useMemo } from 'react';
import { useCallback } from 'react';
import { useState } from 'react';
import { useContext } from 'react';
import { FC } from 'react';
import React from 'react';
import { createContext } from 'react';
import { useEffectOnce } from 'react-use';
import { UrlObject } from 'url';

export interface Breadcrumb {
    title: string;
    href?: string | UrlObject;
}

interface BreadcrumbsContextType {
    breadcrumbs: Breadcrumb[];
    setBreadcrumbs: (breadcrumbs: Breadcrumb[], showRoot?: boolean) => void;
}

const rootBreadcrumb: Breadcrumb = {
    title: 'Главная',
    href: '/',
};

export const BreadcrumbsContext = createContext<BreadcrumbsContextType>({
    breadcrumbs: [],
    setBreadcrumbs: noop,
});

export const BreadcrumbsProvider: FC = ({ children }) => {
    const [breadcrumbs, setBreadcrumbs] = useState<Breadcrumb[]>([]);

    const handleSetBreadcrumbs = useCallback<
        BreadcrumbsContextType['setBreadcrumbs']
    >((nextBreadcrumbs, showRoot = true) => {
        setBreadcrumbs(
            showRoot ? [rootBreadcrumb, ...nextBreadcrumbs] : nextBreadcrumbs
        );
    }, []);

    const value = useMemo(
        () => ({
            breadcrumbs,
            setBreadcrumbs: handleSetBreadcrumbs,
        }),
        [breadcrumbs, handleSetBreadcrumbs]
    );

    return (
        <BreadcrumbsContext.Provider value={value}>
            {children}
        </BreadcrumbsContext.Provider>
    );
};

export const useBreadcrumbsContext = () => useContext(BreadcrumbsContext);

export const useBreadcrumbs = (
    breadcrumbs: Breadcrumb[],
    showRoot?: boolean,
    dependency: any[] = []
) => {
    const { setBreadcrumbs } = useContext(BreadcrumbsContext);

    useEffect(() => {
        setBreadcrumbs(breadcrumbs, showRoot);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, dependency);
};

export const useResetBreadcrumbs = () => {
    const { setBreadcrumbs } = useContext(BreadcrumbsContext);

    useEffectOnce(() => {
        setBreadcrumbs([]);
    });
};
