import React, { FunctionComponent } from 'react';

import { IconsProps } from './Icons.type';

export const ThreeLinesIcon: FunctionComponent<IconsProps> = ({
    fill = '#333333',
}) => (
    <svg
        width="26"
        height="18"
        viewBox="0 0 26 18"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M25 2H1C0.453333 2 0 1.54667 0 1C0 0.453333 0.453333 0 1 0H25C25.5467 0 26 0.453333 26 1C26 1.54667 25.5467 2 25 2ZM25 10H1C0.453333 10 0 9.54667 0 9C0 8.45333 0.453333 8 1 8H25C25.5467 8 26 8.45333 26 9C26 9.54667 25.5467 10 25 10ZM1 18H25C25.5467 18 26 17.5467 26 17C26 16.4533 25.5467 16 25 16H1C0.453333 16 0 16.4533 0 17C0 17.5467 0.453333 18 1 18Z"
            fill={fill}
        />
    </svg>
);
