import Script from 'next/script';
import { FC } from 'react';

export const PushNotification: FC = () => {
    return (
        <Script
            src="https://estp.push4site.com/sdk"
            strategy="afterInteractive"
        />
    );
};
