import { FC } from 'react';
import React from 'react';
import { SvgIcon } from '../../base-components/SvgIcon';

export const TariffsIcon: FC = () => (
    <SvgIcon width={20} height={22} noFill>
        <path
            d="M7.49935 1.66675H12.4993C16.666 1.66675 18.3327 3.33341 18.3327 7.50008V12.5001C18.3327 16.6667 16.666 18.3334 12.4993 18.3334H7.49935C3.33268 18.3334 1.66602 16.6667 1.66602 12.5001V7.50008C1.66602 3.33341 3.33268 1.66675 7.49935 1.66675Z"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M7.14258 12.7251L12.5926 7.27515"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M7.48399 8.64179C8.05009 8.64179 8.50898 8.1829 8.50898 7.61681C8.50898 7.05071 8.05009 6.5918 7.48399 6.5918C6.9179 6.5918 6.45898 7.05071 6.45898 7.61681C6.45898 8.1829 6.9179 8.64179 7.48399 8.64179Z"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M12.9332 13.4084C13.4993 13.4084 13.9582 12.9495 13.9582 12.3834C13.9582 11.8173 13.4993 11.3584 12.9332 11.3584C12.3671 11.3584 11.9082 11.8173 11.9082 12.3834C11.9082 12.9495 12.3671 13.4084 12.9332 13.4084Z"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </SvgIcon>
);
