import Script from 'next/script';
import { isProduction } from '../../browser';

export const YM_TRACKING_ID = process.env.NEXT_PUBLIC_YM_ID;

export const AnalyticsSnippet = () => {
    return (
        <Script
            id="ym-init"
            strategy="afterInteractive"
            dangerouslySetInnerHTML={{
                __html: `
(function(m,e,t,r,i,k,a){m[i]=m[i]||function(){(m[i].a=m[i].a||[]).push(arguments)};
m[i].l=1*new Date();k=e.createElement(t),a=e.getElementsByTagName(t)[0],k.async=1,k.src=r,a.parentNode.insertBefore(k,a)})
(window, document, "script", "https://mc.yandex.ru/metrika/tag.js", "ym");
          `,
            }}
        />
    );
};

export const init = () => {
    if (isProduction && typeof window.ym === 'function' && YM_TRACKING_ID) {
        window.ym(YM_TRACKING_ID, 'init', {
            defer: true,
            clickmap: true,
            trackLinks: true,
            accurateTrackBounce: true,
        });
    }
};

export const pageview = (url: string) => {
    if (isProduction && typeof window.ym === 'function' && YM_TRACKING_ID) {
        window.ym(YM_TRACKING_ID, 'hit', url);
    }
};
