import clsx from 'clsx';
import { MutableRefObject } from 'react';
import { RefObject } from 'react';
import { Ref } from 'react';
import { useMemo } from 'react';
import { InputHTMLAttributes } from 'react';
import { FC } from 'react';
import React from 'react';
import { ErrorMessage } from './ErrorMessage';
import compactStyles from './FormTextInputCompact.module.scss';
import defaultStyles from './FormTextInputDefault.module.scss';
import materialStyles from './FormTextInputMaterial.module.scss';

const styles = {
    default: defaultStyles,
    material: materialStyles,
    compact: compactStyles,
};

export interface Props extends InputHTMLAttributes<HTMLInputElement> {
    label?: string;
    appearance?: 'default' | 'material' | 'compact';
    hasError?: boolean;
    errorMessage?: string;
    inputRef?: React.LegacyRef<HTMLInputElement>;
}

export const TextInput: FC<Props> = ({
    label,
    appearance = 'default',
    placeholder,
    className,
    inputRef,
    hasError,
    errorMessage,
    ...props
}) => {
    const isMd = appearance === 'material';

    const appearanceProps = useMemo(() => {
        return isMd
            ? {
                  required: true,
                  formNoValidate: true,
              }
            : {
                  placeholder: placeholder || label || '',
              };
    }, [isMd, label, placeholder]);

    return (
        <div className={className}>
            <label
                className={clsx(
                    styles[appearance].field,
                    hasError && styles[appearance].error
                )}
            >
                <input
                    className={clsx(
                        styles[appearance].input,
                        hasError && styles[appearance].error
                    )}
                    ref={inputRef}
                    {...appearanceProps}
                    {...props}
                />
                {isMd && (
                    <div
                        className={clsx(
                            styles[appearance].label,
                            hasError && styles[appearance].error
                        )}
                    >
                        {label}
                    </div>
                )}
            </label>

            {hasError && (
                <ErrorMessage className={styles[appearance].errorMessage}>
                    {errorMessage}
                </ErrorMessage>
            )}
        </div>
    );
};
