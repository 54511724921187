const ESTP_ORIGIN = process.env.ESTP_ORIGIN;

const getOrigin = () => {
    if (ESTP_ORIGIN) {
        return ESTP_ORIGIN;
    }
    if (typeof window !== 'undefined') {
        return window.location.origin;
    }
    throw new Error("Can't detect site origin");
};

export const getHref = (url: string = '') => {
    const origin = getOrigin().replace('new.', '');
    return new URL(url, origin).href;
};
