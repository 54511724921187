export const numberWithCommas = (x: number, comma: string) =>
    x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, comma);

export const modifyContactToLink = (
    contact: string,
    type: 'phone' | 'email' | 'skype'
): string => {
    switch (type) {
        case 'email':
            return `mailto:${contact}`;
        case 'phone':
            return `tel:${contact.replace(/[ ()-]/gm, '')}`;
        case 'skype':
            return `skype:${contact}?call`;
        default:
            return contact;
    }
};

export const changeTransformValueAndDirection = (
    pos: number,
    direction: 'up' | 'down',
    step: number,
    maxMove: number
) => {
    let objectGrow = direction;
    let cord = 0;

    if (pos + step > maxMove) {
        objectGrow = 'down';
    }

    if (pos - step < -maxMove) {
        objectGrow = 'up';
    }

    if (direction === 'up') {
        cord = pos + step;
    }

    if (direction === 'down') {
        cord = pos - step;
    }

    return { objectGrow, cord };
};

export const getRandomElementFromArray = (arr: any[]) =>
    arr[Math.floor(Math.random() * arr.length)];

export const fillArrayOfObjectsWithColors = (arr: any[], colors: string[]) => {
    let i = 0;

    return arr.map((item) => {
        const result = { ...item, color: colors[i] };

        i = i === colors.length - 1 ? 0 : i + 1;

        return result;
    });
};

export const notEmpty = <T>(v: T | null | undefined): v is T => !!v;
