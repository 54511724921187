import React, { FunctionComponent } from 'react';

import { IconsProps } from './Icons.type';

export const ShareIcon: FunctionComponent<IconsProps> = (props) => (
    <svg
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <path
            d="M16.0283 13.75C14.3059 13.75 12.9033 15.1517 12.9033 16.875C12.9033 18.5983 14.3059 20 16.0283 20C17.7509 20 19.1533 18.5983 19.1533 16.875C19.1533 15.1517 17.7509 13.75 16.0283 13.75ZM16.0283 18.75C14.995 18.75 14.1533 17.9092 14.1533 16.875C14.1533 15.8408 14.995 15 16.0283 15C17.0616 15 17.9033 15.8408 17.9033 16.875C17.9033 17.9092 17.0616 18.75 16.0283 18.75Z"
            fill="#A0A4B5"
        />
        <path
            d="M16.0283 0C14.3059 0 12.9033 1.40167 12.9033 3.125C12.9033 4.84833 14.3059 6.25 16.0283 6.25C17.7509 6.25 19.1533 4.84833 19.1533 3.125C19.1533 1.40167 17.7509 0 16.0283 0ZM16.0283 5C14.995 5 14.1533 4.15924 14.1533 3.125C14.1533 2.09076 14.995 1.25 16.0283 1.25C17.0616 1.25 17.9033 2.09076 17.9033 3.125C17.9033 4.15924 17.0616 5 16.0283 5Z"
            fill="#A0A4B5"
        />
        <path
            d="M3.94531 6.875C2.22275 6.875 0.820312 8.27667 0.820312 10C0.820312 11.7233 2.22275 13.125 3.94531 13.125C5.66788 13.125 7.07031 11.7233 7.07031 10C7.07031 8.27667 5.66788 6.875 3.94531 6.875ZM3.94531 11.875C2.91199 11.875 2.07031 11.0342 2.07031 10C2.07031 8.96576 2.91199 8.125 3.94531 8.125C4.97864 8.125 5.82031 8.96576 5.82031 10C5.82031 11.0342 4.97864 11.875 3.94531 11.875Z"
            fill="#A0A4B5"
        />
        <path
            d="M6.12114 10.6085C5.90355 10.6085 5.69191 10.7218 5.57778 10.9234C5.40688 11.2234 5.5111 11.6052 5.81108 11.7759L13.5436 16.1843C13.8427 16.3567 14.2252 16.2518 14.3952 15.9509C14.5661 15.6509 14.4619 15.2693 14.1619 15.0984L6.42937 10.6901C6.33187 10.6352 6.22612 10.6085 6.12114 10.6085Z"
            fill="#A0A4B5"
        />
        <path
            d="M13.8529 3.73341C13.7479 3.73341 13.642 3.76011 13.5445 3.81581L5.81199 8.22421C5.51201 8.39496 5.40794 8.77673 5.57869 9.07672C5.74867 9.37747 6.13045 9.48169 6.43043 9.31002L14.1628 4.90177C14.4628 4.73087 14.567 4.34925 14.3963 4.04927C14.2812 3.84678 14.0696 3.73341 13.8529 3.73341Z"
            fill="#A0A4B5"
        />
    </svg>
);
