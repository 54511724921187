/* eslint-disable max-len */
import setId from './utils/setId';

export const newsDummy = [
    {
        type: 'local',
        text:
            '# Новые сервисы ESTP.RU  \n\n' +
            'На УЭТП ESTP.RU заработали новые сервисы: «электронный магазин транспортных услуг» и служба, оповещающая о скором истечении срока действия ЭП, а также о необходимости отправки договора на подпись.',
        date: '2 сентября 2020',
        link: '#',
    },
    {
        type: 'local',
        text:
            '# Новый сервис ESTP.RU  \n\n' +
            'Теперь Вы не пропустите сроки подписания договора. С этой целью на УЭТП ESTP.RU реализован новый сервис, обеспечивающий индикацию сроков заключения и подписания договоров.',
        date: '2 сентября 2020',
        link: '#',
    },
    {
        type: 'local',
        text:
            '# НОВЫЕ ТАРИФЫ ДЛЯ УЧАСТНИКОВ  \n\n' +
            'Уважаемые пользователи ESTP.RU! 15 октября 2019 года с 19:00 по московскому времени будут обновлены тарифы. Для дальнейшей работы просим Вас заранее проверить наличие денежных средств для приобретения',
        date: '2 сентября 2020',
        link: '#',
    },
    {
        type: 'global',
        text:
            '# ESTP.RU В I ПОЛУГОДИИ 2019 ГОДА ВОШЛА В 10 КРУПНЕЙШИХ ЭЛЕКТРОННЫХ ТОРГОВЫХ ПЛОЩАДОК РФ  \n\n' +
            'По опубликованным данным Минфина РФ за I полугодие 2019 года площадка ESTP.RU по количеству проводимых закупок вошла в 10-ку',
        date: '2 сентября 2020',
        link: '#',
    },
].map(setId);

export const newsFullDummy = [
    {
        text: '# ESTP.RU В I ПОЛУГОДИИ 2019 ГОДА ВОШЛА В 10 КРУПНЕЙШИХ ЭЛЕКТРОННЫХ ТОРГОВЫХ ПЛОЩАДОК РФ   \n\nМинистерство *финансов* РФ опубликовало отчёт «Мониторинг применения Федерального закона от 18 июля 2011 № 223-ФЗ „О закупках товаров, работ, услуг отдельными видами юридических лиц“ в I полугодии 2019 года», в котором площадка ESTP.RU по количеству проводимых закупок включена в 10-ку крупнейших площадок. При этом на выделенных 10 ЭТП было размещено более 50% извещений о закупках.',
        date: '2 сентября 2020',
        link: '#',
        type: 'global',
    },
    {
        text:
            '# Суды: претензионные письма не доказывают негативный опыт исполнения договоров по Закону N 223-ФЗ  \n\n' +
            'В закупочной документации установили критерий оценки опыта по аналогичным работам с заказчиком: за отсутствие и (или) наличие негативного опыта присуждалось 0 баллов. Негативный опыт подтверждался претензионными письмами.  \n\n' +
            'Участник закупки пожаловался на порядок оценки заявок. Он нарушает принцип равноправия и справедливости.  \n\n' +
            'Контролёры поддержали заказчика: порядок оценки соответствует положению о закупке. Его условия участник не обжаловал.  \n\n' +
            'Суды не согласились с антимонопольным органом: такой способ оценки нельзя применить в равной мере ко всем участникам. Сведения о претензионной работе не публикуют в общедоступных ресурсах, нет единого реестра. Заказчик оценивает заявки произвольно, поскольку участники могут и не сообщить о претензиях;  \n\n' +
            'в закупке не определили, ни за какой период учитываются претензионные письма, ни то, как оценить их обоснованность. При проведении тендера заказчик может инициировать претензию участникам, с которыми он находится в действующих правоотношениях. Это позволит произвольно снизить им баллы;  \n\n' +
            'претензионная работа — это обычная практика деловых отношений. Она не доказывает негативный опыт исполнения контракта или недобросовестность участника закупки;  \n\n' +
            'чтобы отстранить от закупки недобросовестных участников, заказчику стоило установить требование об отсутствии сведений о претендентах в РНП.  \n\n' +
            'ВС РФ не стал пересматривать дело.  \n\n' +
            'Документ: Определение ВС РФ от 17.08.2021 N 302-ЭС21–12951',
        date: '2 сентября 2020',
        link: '#',
        type: 'local',
    },
    {
        text:
            '# ESTP.RU В I ПОЛУГОДИИ 2019 ГОДА ВОШЛА В 10 КРУПНЕЙШИХ ЭЛЕКТРОННЫХ ТОРГОВЫХ ПЛОЩАДОК РФ  \n\n' +
            'В закупочной документации установили критерий оценки опыта по аналогичным работам с заказчиком: за отсутствие и (или) наличие негативного опыта присуждалось 0 баллов. Негативный опыт подтверждался претензионными письмами.  \n\n' +
            '**Участник закупки пожаловался на порядок оценки заявок.** Он нарушает принцип равноправия и справедливости.' +
            'Контролёры поддержали заказчика: порядок оценки соответствует положению о закупке. Его условия участник не обжаловал.  \n\n' +
            'Суды не согласились с антимонопольным органом: такой способ оценки нельзя применить в равной мере ко всем участникам. Сведения о претензионной работе не публикуют в общедоступных ресурсах, нет единого реестра. Заказчик оценивает заявки произвольно, поскольку участники могут и не сообщить о претензиях;  \n\n' +
            'в закупке не определили, ни за какой период учитываются претензионные письма, ни то, как оценить их обоснованность. При проведении тендера заказчик может инициировать претензию участникам, с которыми он находится в действующих правоотношениях. Это позволит произвольно снизить им баллы;  \n\n' +
            'претензионная работа — это обычная практика деловых отношений. Она не доказывает негативный опыт исполнения контракта или недобросовестность участника закупки;  \n\n' +
            'чтобы отстранить от закупки недобросовестных участников, заказчику стоило установить требование об отсутствии сведений о претендентах в РНП.  \n\n' +
            'ВС РФ не стал пересматривать дело.  \n\n' +
            'Документ: Определение ВС РФ от 17.08.2021 N 302-ЭС21–12951',
        date: '2 сентября 2020',
        link: '#',
        type: 'local',
    },
].map(setId);
