import DefaultMoment from 'moment';
import 'moment/locale/ru';

export const createMskMoment = (Moment = DefaultMoment, locale = 'ru') => {
    return function mskMoment(datetime: any) {
        let momentDate;

        if (!datetime) {
            return null;
        }

        if (typeof datetime === 'string') {
            if (datetime === 'now') {
                momentDate = Moment().utcOffset('+03:00', false);
            } else {
                const hasTimezone = /[Z+]/.test(datetime);
                momentDate = Moment(datetime, Moment.ISO_8601, true).utcOffset(
                    '+03:00',
                    !hasTimezone
                );
            }
        } else {
            momentDate = Moment(datetime).utcOffset('+03:00', false);
        }

        return momentDate.isValid() ? momentDate.locale(locale) : null;
    };
};

export default createMskMoment();
