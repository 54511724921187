import { useCallback } from 'react';
import { useState } from 'react';

export default function useModalState(): [boolean, () => void, () => void] {
    const [isVisible, setVisible] = useState(false);

    const showModal = useCallback(() => {
        setVisible(true);
    }, []);

    const hideModal = useCallback(() => {
        setVisible(false);
    }, []);

    return [isVisible, showModal, hideModal];
}
