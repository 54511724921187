import { FC } from 'react';
import React from 'react';
import { SvgIcon } from '../../base-components/SvgIcon';

export const AboutIcon: FC = () => (
    <SvgIcon width={20} height={22} noFill>
        <path
            d="M7.49961 10.8333C6.94961 11.1083 6.49128 11.5166 6.14961 12.0249C5.95794 12.3166 5.95794 12.6833 6.14961 12.9749C6.49128 13.4833 6.94961 13.8916 7.49961 14.1666"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M12.6758 10.8333C13.2258 11.1083 13.6841 11.5166 14.0258 12.0249C14.2174 12.3166 14.2174 12.6833 14.0258 12.9749C13.6841 13.4833 13.2258 13.8916 12.6758 14.1666"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M7.49935 18.3334H12.4993C16.666 18.3334 18.3327 16.6667 18.3327 12.5001V7.50008C18.3327 3.33341 16.666 1.66675 12.4993 1.66675H7.49935C3.33268 1.66675 1.66602 3.33341 1.66602 7.50008V12.5001C1.66602 16.6667 3.33268 18.3334 7.49935 18.3334Z"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M1.85742 6.67508L17.8741 6.66675"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </SvgIcon>
);
