import range from 'lodash/range';
import { AboutProps } from '../Info/About/About.type';
import setId from './utils/setId';

const images = range(0, 6).map(
    (index) => `/assets/dummy-data/peoples/${index + 1}.png`
);

export const peoplesDummy: AboutProps['peoples'] = [
    {
        name: 'Константинов Илья Ильич',
        position: 'Генеральный директор',
        image: images[0],
    },
    {
        name: 'Король Анатолий Евгеньевич',
        position: 'Директор по развитию',
        image: images[1],
    },
    {
        name: 'Коробов Кирилл Валерьевич',
        position: 'Заместитель директора по правовым вопросам',
        image: images[2],
    },
    {
        name: 'Калинина Светлана Анатольевна',
        position: 'Руководитель отдела клиентского обслуживания',
        image: images[3],
    },
    {
        name: 'Веретенников Алексей Андреевич',
        position: 'Руководитель отдела разработки',
        image: images[4],
    },
    {
        name: 'Жидкова Оксана Геннадьевна',
        position: 'Главный бухгалтер',
        image: images[5],
    },
].map(setId);
