/* eslint-disable max-len */
interface IMemberDummy {
    bonuses: string[];
}

export const membersDummy: IMemberDummy = {
    bonuses: [
        'Бесплатная регистрация/аккредитация',
        'Персональный подход к решению вопросов наших клиентов',
        'Отсутствие скрытых платежей (доступ к личному кабинету и всем действиям с закупкой, в которой Вы принимаете участие, не ограничен сроками действия платных лицензий)',
        'Своевременное оповещение о закупках, проводимых на ESTP.RU (бесплатная информационная рассылка по интересным для Вас закупкам)',
        'Оперативное зачисление денежных средств на счёт',
        'Бесплатный электронный документооборот',
        'Удалённая техническая поддержка для настройки рабочего места',
    ],
};
