import { useField } from 'formik';
import { FC } from 'react';
import React from 'react';
import { TextInput } from './TextInput';
import { Props as TextInputProps } from './TextInput';

interface Props extends TextInputProps {
    name: string;
}

export const FormTextInput: FC<Props> = ({ name, ...props }) => {
    const [field, meta] = useField(name);

    return (
        <TextInput
            {...field}
            {...props}
            hasError={Boolean(meta.touched && meta.error)}
            errorMessage={meta.error}
        />
    );
};
