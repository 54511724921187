import React from 'react';
import { SvgIcon } from '../../base-components/SvgIcon';
import { IconsProps } from './Icons.type';

/** Белая галочка с квадратными углами */
export const CheckWhiteIcon: React.FC<IconsProps> = ({
    className,
    fill = 'white',
}) => (
    <SvgIcon width={13} height={10} className={className} noFill noStroke>
        <path
            d="M12.8723 0H11.7587C11.6026 0 11.4545 0.069892 11.3589 0.189485L4.79063 8.30162L1.64112 4.41096C1.59348 4.35199 1.53275 4.3043 1.4635 4.27149C1.39424 4.23867 1.31826 4.22157 1.24126 4.22148H0.127704C0.0209677 4.22148 -0.0379761 4.34107 0.0273399 4.42184L4.39077 9.81129C4.59468 10.0629 4.98658 10.0629 5.19209 9.81129L12.9727 0.198804C13.038 0.119593 12.979 0 12.8723 0Z"
            fill={fill}
        />
    </SvgIcon>
);
