import React, { FunctionComponent } from 'react';
import { IconsProps } from './Icons.type';

export const LogoIcon: FunctionComponent<IconsProps> = (props) => (
    <svg
        width="122"
        height="55"
        viewBox="0 0 122 55"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <g clipPath="url(#clip0_3905_6415)">
            <path
                d="M59.2576 37.0576H50.9508C50.689 37.0576 50.5053 37.3207 50.6089 37.5554C51.1181 38.7093 52.2001 39.2871 53.8531 39.2871C54.9314 39.2871 55.7752 38.9653 56.3808 38.3217C56.4971 38.1972 56.6863 38.167 56.8372 38.2506L58.7339 39.3209C58.9284 39.4311 58.9794 39.6836 58.8412 39.856C57.6537 41.3264 55.9752 42.0607 53.8058 42.0607C51.8 42.0607 50.1925 41.4686 48.9796 40.2827C47.7667 39.0969 47.1611 37.6034 47.1611 35.8006C47.1611 33.9978 47.7594 32.5452 48.956 31.3433C50.1361 30.1415 51.6727 29.5405 53.564 29.5405C55.4552 29.5405 56.8208 30.1415 57.9537 31.3433C59.1176 32.5452 59.6995 34.0316 59.6995 35.8006C59.6995 36.0619 59.674 36.3749 59.6249 36.7429C59.6013 36.9225 59.4431 37.0576 59.2594 37.0576H59.2576ZM50.8817 34.6858H56.0789C56.3335 34.6858 56.5135 34.4387 56.428 34.204C56.2262 33.6618 55.9152 33.2297 55.4915 32.9079C54.9496 32.4972 54.3077 32.291 53.564 32.291C52.7238 32.291 52.0273 32.5008 51.4782 32.9186C51.0508 33.2439 50.7362 33.6706 50.5344 34.2005C50.4453 34.4351 50.6253 34.6841 50.8817 34.6841V34.6858Z"
                fill="black"
            />
            <path
                d="M64.2822 33.2404C64.2822 33.7471 65.0169 34.1809 66.4899 34.5436C67.0063 34.6539 67.4755 34.7854 67.8955 34.9348C68.3156 35.0859 68.7393 35.2993 69.1685 35.5748C69.5958 35.8522 69.9322 36.2184 70.1741 36.6771C70.4159 37.1358 70.5378 37.6657 70.5378 38.2666C70.5378 39.4845 70.0686 40.425 69.1321 41.0882C68.1938 41.7353 67.039 42.0607 65.6643 42.0607C63.3203 42.0607 61.6782 41.2268 60.7381 39.5592C60.6399 39.3849 60.7035 39.1662 60.8799 39.0684L62.9293 37.9412C63.1093 37.8417 63.3403 37.9057 63.4348 38.0853C63.8495 38.8871 64.5932 39.2871 65.6643 39.2871C66.7354 39.2871 67.3373 38.9475 67.3373 38.2684C67.3373 37.7635 66.6008 37.3279 65.1297 36.9652C64.5805 36.8229 64.1113 36.6807 63.724 36.5385C63.3366 36.3962 62.9148 36.1864 62.4638 35.9109C62.011 35.6353 61.6673 35.2797 61.4327 34.8441C61.1981 34.4103 61.0817 33.9 61.0817 33.3151C61.0817 32.1452 61.5182 31.2278 62.391 30.5646C63.2803 29.8855 64.3804 29.5459 65.6897 29.5459C66.6754 29.5459 67.5737 29.7628 68.3811 30.1984C69.0739 30.5718 69.6431 31.0802 70.0868 31.7274C70.2068 31.9016 70.1432 32.1417 69.954 32.243L67.9519 33.3115C67.7846 33.4004 67.5719 33.3542 67.47 33.196C67.0736 32.5808 66.4808 32.2732 65.6897 32.2732C65.286 32.2732 64.9496 32.3639 64.6841 32.5453C64.4168 32.7266 64.2841 32.9595 64.2841 33.244L64.2822 33.2404Z"
                fill="black"
            />
            <path
                d="M79.1245 30.2338V32.4527C79.1245 32.6518 78.959 32.8136 78.7553 32.8136H76.7532C76.5495 32.8136 76.384 32.9754 76.384 33.1745V37.7456C76.384 38.1563 76.4895 38.4567 76.6986 38.647C76.9077 38.8372 77.2151 38.9439 77.6206 38.967C77.9352 38.9848 78.308 38.9865 78.7408 38.9723C78.9499 38.9652 79.1245 39.127 79.1245 39.3315V41.4045C79.1245 41.5894 78.9826 41.7459 78.7935 41.7637C76.8477 41.945 75.4511 41.7405 74.6019 41.1485C73.7054 40.5245 73.2563 39.3901 73.2563 37.7456V33.1745C73.2563 32.9754 73.0908 32.8136 72.8871 32.8136H71.5142C71.3105 32.8136 71.145 32.6518 71.145 32.4527V30.2338C71.145 30.0347 71.3105 29.8729 71.5142 29.8729H72.8871C73.0908 29.8729 73.2563 29.7111 73.2563 29.512V27.7448C73.2563 27.5865 73.3635 27.4461 73.5181 27.3998L75.9094 26.6922C76.1458 26.6229 76.384 26.7953 76.384 27.0371V29.5102C76.384 29.7094 76.5495 29.8711 76.7532 29.8711H78.7553C78.959 29.8711 79.1245 30.0329 79.1245 30.2321V30.2338Z"
                fill="black"
            />
            <path
                d="M88.5822 29.5405C90.2152 29.5405 91.6045 30.1486 92.7538 31.3665C93.9176 32.5843 94.4995 34.0618 94.4995 35.8006C94.4995 37.5394 93.9176 39.0577 92.7538 40.2578C91.6227 41.4597 90.2316 42.0607 88.5822 42.0607C87.2311 42.0607 86.1255 41.6944 85.2654 40.9637C85.0253 40.7592 84.6525 40.921 84.6525 41.2321V46.1108C84.6525 46.3099 84.4871 46.4717 84.2834 46.4717H81.8921C81.6884 46.4717 81.5229 46.3099 81.5229 46.1108V30.2339C81.5229 30.0348 81.6884 29.873 81.8921 29.873H84.2834C84.4871 29.873 84.6525 30.0348 84.6525 30.2339V30.369C84.6525 30.6802 85.0253 30.842 85.2654 30.6375C86.1255 29.9068 87.2311 29.5405 88.5822 29.5405ZM85.6 38.2186C86.231 38.8355 87.0311 39.1431 88.0003 39.1431C88.9696 39.1431 89.7715 38.8355 90.4007 38.2186C91.0463 37.5874 91.3699 36.7802 91.3699 35.8006C91.3699 34.821 91.0463 34.0227 90.4007 33.4057C89.7697 32.7728 88.9696 32.4581 88.0003 32.4581C87.0311 32.4581 86.2291 32.7746 85.6 33.4057C84.9689 34.0227 84.6544 34.821 84.6544 35.8006C84.6544 36.7802 84.9689 37.5874 85.6 38.2186Z"
                fill="black"
            />
            <path
                d="M99.6167 40.0212C99.6167 40.5742 99.4149 41.0489 99.0112 41.4436C98.6075 41.8383 98.122 42.0356 97.5564 42.0356C96.9909 42.0356 96.5053 41.8383 96.1016 41.4436C95.6979 41.0489 95.4961 40.5742 95.4961 40.0212C95.4961 39.4683 95.6979 38.9936 96.1016 38.5989C96.5053 38.2042 96.9909 38.0068 97.5564 38.0068C98.122 38.0068 98.6075 38.2042 99.0112 38.5989C99.4149 38.9936 99.6167 39.4683 99.6167 40.0212Z"
                fill="black"
            />
            <path
                d="M105.363 30.2339V30.5931C105.363 30.9273 105.783 31.0785 106.01 30.8278C106.227 30.5895 106.48 30.3868 106.771 30.2162C107.329 29.889 107.958 29.7006 108.655 29.649C108.867 29.633 109.051 29.8001 109.051 30.0099V32.6555C109.051 32.8617 108.873 33.0235 108.662 33.0146C107.862 32.9808 107.134 33.1817 106.48 33.6173C105.736 34.108 105.365 34.9205 105.365 36.0602V41.3673C105.365 41.5664 105.199 41.7282 104.996 41.7282H102.605C102.401 41.7282 102.235 41.5664 102.235 41.3673V30.2339C102.235 30.0348 102.401 29.873 102.605 29.873H104.996C105.199 29.873 105.365 30.0348 105.365 30.2339H105.363Z"
                fill="black"
            />
            <path
                d="M118.871 36.2753V30.234C118.871 30.0348 119.036 29.873 119.24 29.873H121.631C121.835 29.873 122 30.0348 122 30.234V41.3673C122 41.5664 121.835 41.7282 121.631 41.7282H119.24C119.036 41.7282 118.871 41.5664 118.871 41.3673C118.871 41.0579 118.496 40.8908 118.261 41.0971C117.527 41.7389 116.527 42.0607 115.257 42.0607C113.988 42.0607 112.831 41.6269 111.959 40.7575C111.102 39.8721 110.673 38.6702 110.673 37.1536V30.2357C110.673 30.0366 110.838 29.8748 111.042 29.8748H113.433C113.637 29.8748 113.802 30.0366 113.802 30.2357V36.7749C113.802 37.5501 114.017 38.1493 114.444 38.5777C114.872 39.0044 115.459 39.2178 116.203 39.2178C117.027 39.2178 117.678 38.9689 118.156 38.4711C118.632 37.9733 118.871 37.2425 118.871 36.2771V36.2753Z"
                fill="black"
            />
            <path
                d="M32.9056 16.5361L20.6292 30.0395C20.551 30.1248 20.5073 30.2368 20.5073 30.3524V43.3134C20.5073 43.7294 21.0183 43.9392 21.322 43.6512L31.8018 33.7037C31.8127 33.6931 31.8255 33.6824 31.8382 33.6717C32.2146 33.3766 38.0646 28.5691 33.7167 16.6926C33.593 16.3566 33.1457 16.2712 32.9038 16.5379L32.9056 16.5361Z"
                fill="url(#paint0_linear_3905_6415)"
            />
            <path
                d="M20.5073 0.471158V26.2101C20.5073 26.6475 21.0638 26.8484 21.3547 26.5142L32.1855 14.0438C32.2965 13.9158 32.3292 13.7416 32.2728 13.5851C31.8309 12.3637 29.516 6.94279 21.3002 0.112018C20.9892 -0.147559 20.5073 0.0711255 20.5073 0.471158Z"
                fill="url(#paint1_linear_3905_6415)"
            />
            <path
                d="M18.7131 16.5758V34.8368C18.7131 34.9186 18.6913 34.9986 18.6495 35.0697C18.104 35.996 14.1942 42.7148 13.0813 46.6973C12.9577 47.1383 12.3285 47.1632 12.1667 46.7347C11.3174 44.4785 11.1101 44.3309 9.94812 41.4952C9.92448 41.44 9.91357 41.3796 9.91357 41.3191V27.638C9.91357 27.542 9.94267 27.4496 9.99904 27.3713L17.8366 16.3109C18.1058 15.9322 18.7113 16.1171 18.7113 16.5776L18.7131 16.5758Z"
                fill="url(#paint2_linear_3905_6415)"
            />
            <path
                d="M20.4844 55.655C34.6386 55.655 46.1129 53.8961 46.1129 51.7264C46.1129 49.5567 34.6386 47.7979 20.4844 47.7979C6.33022 47.7979 -5.14404 49.5567 -5.14404 51.7264C-5.14404 53.8961 6.33022 55.655 20.4844 55.655Z"
                fill="url(#paint3_radial_3905_6415)"
            />
        </g>
        <defs>
            <linearGradient
                id="paint0_linear_3905_6415"
                x1="20.5073"
                y1="30.0803"
                x2="35.3187"
                y2="30.0803"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#F08F76" />
                <stop offset="1" stopColor="#DE4841" />
            </linearGradient>
            <linearGradient
                id="paint1_linear_3905_6415"
                x1="20.5073"
                y1="13.3415"
                x2="32.3001"
                y2="13.3415"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#86D2EA" />
                <stop offset="1" stopColor="#53A9C9" />
            </linearGradient>
            <linearGradient
                id="paint2_linear_3905_6415"
                x1="9.91357"
                y1="31.5743"
                x2="18.7131"
                y2="31.5743"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#86D2EA" />
                <stop offset="1" stopColor="#53A9C9" />
            </linearGradient>
            <radialGradient
                id="paint3_radial_3905_6415"
                cx="0"
                cy="0"
                r="1"
                gradientUnits="userSpaceOnUse"
                gradientTransform="translate(20.5804 51.9365) rotate(-0.203488) scale(25.7246 3.88655)"
            >
                <stop stopColor="#68A9C9" stopOpacity="0.6" />
                <stop offset="0.18" stopColor="#7BB4CF" stopOpacity="0.52" />
                <stop offset="0.53" stopColor="#AED1E2" stopOpacity="0.32" />
                <stop offset="1" stopColor="white" stopOpacity="0" />
            </radialGradient>
            <clipPath id="clip0_3905_6415">
                <rect width="122" height="55" fill="white" />
            </clipPath>
        </defs>
    </svg>
);
