import { useRouter } from 'next/router';
import { useEffect } from 'react';
import { useEffectOnce } from 'react-use';
import * as ym from './ym';

declare global {
    interface Window {
        ym: (trackingId: string, method: string, params: any) => void;
    }
}

export const AnalyticsSnippet = () => {
    const YM = ym.AnalyticsSnippet;

    return (
        <>
            <YM />
        </>
    );
};

export const useAnalytics = () => {
    const router = useRouter();

    useEffectOnce(() => {
        ym.init();
    });

    useEffect(() => {
        const handleRouteChange = (url: string) => {
            pageview(url);
        };
        router.events.on('routeChangeComplete', handleRouteChange);
        return () => {
            router.events.off('routeChangeComplete', handleRouteChange);
        };
    }, [router.events]);
};

/**
 * Регистрация просмотра страницы
 */
export const pageview = (url: string) => {
    ym.pageview(url);
};
