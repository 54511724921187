import Head from 'next/head';
import { FC } from 'react';
import { metaTags } from './_dummyData';
interface Props {
    title?: string;
    description?: string;
    url?: string;
    keywords?: string;
}

const Meta: FC<Props> = ({ title, description, url, keywords }) => {
    return (
        <Head>
            <title>{title}</title>
            <meta name="description" content={description} key="description" />
            <meta name="keywords" content={keywords} key="keywords" />
            <meta name="yandex-verification" content="c4a5ec5979af73cd" />
            <meta property="og:title" content={title} key="title" />
            <meta property="og:url" content={url} key="url" />
            <meta
                property="og:description"
                content={description}
                key="ogDescription"
            />
            <meta
                name="viewport"
                content="width=device-width, initial-scale=1"
            />
        </Head>
    );
};

Meta.defaultProps = {
    title: metaTags.main.title,
    description: metaTags.main.description,
    url: metaTags.main.url,
    keywords: metaTags.main.keywords,
};

export default Meta;
